    var sessionIdDisplayTag  = 'lblSignSessionId';
    var imgDisplayField  = 'sign2';
    var isImageTag = true;
    var popupWindow;
	var Signature = {};
	//var qrCodeUrl = 'http://www.mobile-barcodes.com/qr-code-generator/generator.php';
	var qrCodeUrl = 'https://chart.apis.google.com/chart';
	var isPreviewClicked = false;	
	var signatureProtocol;
	Signature.socket = null;
	var userName = 'TESTEASYINKSIGN';
	var password = 'TESTEASYINK811*';
	var signToolHost = 'test.mysignature.net';
	var signToolContext = '/mysignature';
	
	
	
	Signature.connect = (function(host) {
		if (Signature.socket != null) {
			Signature.socket.close();
		}
		if ('WebSocket' in window) {
			Signature.socket = new WebSocket(host);
			
		} else if ('MozWebSocket' in window) {
			Signature.socket = new MozWebSocket(host);
		} else {
			if(document.getElementById('hiddenWebSocketSupported').value != undefined){
				document.getElementById('hiddenWebSocketSupported').value= false;
				setCss('none');
				dialog.generateSignDialogWithSessionId();
			}
			return;
		}
		
		Signature.socket.onopen = function() {
			console.log('Hidden Var'+document.getElementById('hiddenWebSocketSupported'));
			console.log('HiddenWebSocketSupported ---------->'+document.getElementById('hiddenWebSocketSupported').value);
			if(document.getElementById('hiddenWebSocketSupported').value != undefined){
				console.log('Inside Hidden undefined value');
				document.getElementById('hiddenWebSocketSupported').value= true;
				//setCss('');
			}
		};

		Signature.socket.onclose = function() {
			//Commented by CPNVP 03/24/2015 PR 617 - START
			/*dialog.generateSignDialogWithSessionId();
			if(isPreviewClicked){
				 
				Signature.initialize();
				isPreviewClicked = false;
			}*/
			//Commented by CPNVP 03/24/2015 PR 617 - END
			console.log('close connection');
		};

		Signature.socket.onmessage = function(message) {
			console.log('Info: WebSocket onmessage.');
			var objData = eval("(" + message.data + ')');
			console.log('objData -->> ' + objData);
			console.log('objData.sessionId is ---------------->'+objData.sessionId);
			document.getElementById('sessionID').value = objData.sessionId;
			console.log('After session id');
			console.log('objData.imageData is ----------->'+objData.imageData);
			if(objData.imageData != undefined ){
				console.log('ImageData is not undefiined');
				setImage(objData.imageData , isImageTag);
			}
		    
		    else{
		    	console.log('Inside ELSE LOOPO');
		    	if(objData.sessionId != undefined){
		    		console.log('INSIDe IF LOOP');
		    		setSessionIdAndQrCode(objData.sessionId);
		    		dialog.generateSignDialogWithSessionId();
		    	}
		    }
			   
		};
	});
	
	Signature.initialize = function() {
		var URL;
		
		if (window.location.protocol != "https:"){
			signatureProtocol = "http://";
			URL = 'ws://'+signToolHost + signToolContext + '/signature/client?user='+userName+'&password='+ password;
		}else{
			signatureProtocol = "https://";
			URL = 'wss://'+signToolHost + signToolContext + '/signature/client?user='+userName+'&password='+ password;
		 
		}
		
		Signature.connect(URL);
		
	};

	var Console = {};

	Console.log = (function(message) {
		console.log('Message:::' + message);
	});
	function setCss(display){
		if(document.getElementById('pnlSignQrCode1') != undefined){
			document.getElementById('pnlSignQrCode1').style.display = display;
		}
		if(document.getElementById('pnlSignQrCode2') != undefined){
			document.getElementById('pnlSignQrCode2').style.display = display;
		}
		if(document.getElementById('lblSignOpt1') != undefined){
			document.getElementById('lblSignOpt1').style.display= display;
		}
		if(document.getElementById('lblSignInfo') != undefined){
			document.getElementById('lblSignInfo').style.display= display;
		}
		/*CPNVP 10/31/2014 PR 7637 - START*/
		if(document.getElementById('lblSignUrl') != undefined){
			document.getElementById('lblSignUrl').style.display= display;
		}
		/*CPNVP 10/31/2014 PR 7637 - END*/
		if(document.getElementById('qrCodeImg') != undefined){
			document.getElementById('qrCodeImg').style.display= display;
		}
	}
	function setImage(image , isImageTag){
		if(image != null && image != undefined){
			if(isImageTag){
				console.log('Inside isImageTag ---------------- >');
				var img = document.createElement('img');
		        img.setAttribute("src",image);
				document.getElementById(imgDisplayField).src = image;
				document.getElementById('signatureFile').src = image;
				document.getElementById('sign2').value = image;
				viewerUtility.uploadImage();
				
				/*document.getElementById('hiddenImagePath').value =img.src;
				 document.getElementById('imgNoSign').style.display = 'none';
		         document.getElementById(imgDisplayField).style.display = '';
		         document.getElementById('clearSignLink').style.display = '';*/
			}
			else{
				document.getElementById('signatureCanvas').style.display = '';
		        init();
		        var signatureCanvas = document.getElementById("signatureCanvas");
		        var ctx = signatureCanvas.getContext("2d");
		        var img = document.createElement('img');
		        img.setAttribute("src",image);
		        ctx.clearRect(0, 0, signatureCanvas.width, signatureCanvas.height);

		        ctx.drawImage(img,0,0);
			}
		}
		    	
	}
	function setSessionIdAndQrCode(sessionId){
		console.log('Inside setSessionIdAndQrCode');
		
		//var qrCodeImageSrc = qrCodeUrl+'?str='+signatureProtocol + signToolHost + signToolContext+'/signature.jsp?sessionId='+ sessionId;
		var qrCodeImageSrc = qrCodeUrl+'?cht=qr&chs=300x300&chl='+signatureProtocol + signToolHost + signToolContext+'/signature.jsp?sessionId='+ sessionId + '&chld=H|0';
		
		 console.log('Afrter src is defined');
		
		if(document.getElementById('hiddenSessionId') != undefined){
			document.getElementById('hiddenSessionId').value = sessionId;
		}
		if(document.getElementById('lblSessionId') != undefined){
			document.getElementById('lblSessionId').value = sessionId;
		}
		if(document.getElementById('hiddenQrCodeStr') != undefined){
			document.getElementById('hiddenQrCodeStr').value= qrCodeImageSrc;
		}
		if(document.getElementById('qrCodeImg') != undefined){
			console.log('Inside qrCode Img is not undefined');
			document.getElementById('qrCodeImg').src = qrCodeImageSrc;
			document.getElementById('qrCodeImg').style.display='';
		}
	}
	function setPreviewEvent(){
		this.isPreviewClicked = true;
	}
	function clearSignature(){
		document.getElementById('hiddenImagePath').value ='';
		 document.getElementById('imgNoSign').style.display = '';
         document.getElementById(imgDisplayField).style.display = 'none';
         document.getElementById(imgDisplayField).src = '';
         document.getElementById('clearSignLink').style.display = 'none';
	}